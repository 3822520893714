import React, { Component } from 'react';
import { SPSiteConstants } from '../../utils/Constants';
import { SPSiteCommon } from '../../utils/Common';
import { ErrorCodes } from '../../utils/ErrorCodes';
import axios from 'axios';

class FeedbackFormView extends Component {
    state = {
        isVerified: false,
        disableSend: false
    };
    url = SPSiteConstants.getContactUsUrl();

    onSendClick = e => {
        e.preventDefault();
        this.send();
    }

    validateEmail = email => {
        const { values, updateValidateState } = this.props;
        const emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        if (emailRex.test(email)) {
            values.validateState.emailState = 'has-success';
        }
        else {
            values.validateState.emailState = 'has-danger';
        }

        updateValidateState(values.validateState);
    }

    onNameChange = e => {
        const { handleNameChange } = this.props;
        handleNameChange(e.target.value);
    }

    onEmailChange = e => {
        const { values, handleEmailChange, updateValidateState } = this.props;
        handleEmailChange(e.target.value);

        if (e.target.value !== '') {
            values.validateState.emailState = 'has-success';
            updateValidateState(values.validateState);
        }
    }

    onSubjectChange = e => {
        const { values, handleSubjectChange, updateValidateState } = this.props;
        handleSubjectChange(e.target.value);

        if (e.target.value !== '') {
            values.validateState.subjectState = 'has-success';
            updateValidateState(values.validateState);
        }
    }

    onMessageChange = e => {
        const { values, handleMessageChange, updateValidateState } = this.props;
        handleMessageChange(e.target.value);

        if (e.target.value !== '') {
            values.validateState.messageState = 'has-success';
            updateValidateState(values.validateState);
        }
    }

    send = () => {
        const { values, setLoading, handleEmailChange, handleSubjectChange, handleMessageChange, gotoStep } = this.props;
        const { isVerified } = this.state;

        var me = this;
        values.validateState.emailState = 'has-success';
        values.validateState.subjectState = 'has-success';
        values.validateState.messageState = 'has-success';

        if (values.email.trim() === '') {
            values.validateState.emailState = 'has-danger';
            handleEmailChange('');
        }
        else {
            this.validateEmail(values.email);
        }

        if (values.subject.trim() === '') {
            values.validateState.subjectState = 'has-danger';
            handleSubjectChange('');
        }

        if (values.message.trim() === '') {
            values.validateState.messageState = 'has-danger';
            handleMessageChange('');
        }

        if (values.validateState.emailState === 'has-success' && values.validateState.subjectState === 'has-success' && values.validateState.messageState === 'has-success') {

            if (isVerified === true) {
                setLoading(true);

                this.setState({
                    disableSend: true
                });

                var contactUsRequest = {
                    SenderFullName: values.name.trim(),
                    SenderEmail: values.email,
                    Subject: values.subject.trim(),
                    Message: values.message.trim()
                };

                SPSiteCommon.log('HTTP:: Request - UserRegistrationManagementService - ContactUs');

                axios.post(this.url, contactUsRequest)
                    .then(function (response) {
                        SPSiteCommon.log('HTTP:: Response - UserRegistrationManagementService - ContactUs');
                        SPSiteCommon.log(response);

                        setLoading(false);

                        me.setState({
                            disableSend: false
                        });

                        var statusCode = response.data.StatusCode;

                        if (statusCode === ErrorCodes.CONTACTUS_STATUS_SENDEREMAILMISSING) {
                        }
                        else if (statusCode === ErrorCodes.CONTACTUS_STATUS_EMAILSUBJECTMISSING) {
                        }
                        else if (statusCode === ErrorCodes.CONTACTUS_STATUS_EMAILMESSAGEMISSING) {
                        }
                        else if (statusCode === ErrorCodes.CONTACTUS_STATUS_EMAILSENT) {
                            gotoStep(2);
                        }
                        else {
                            //CONTACTUS_STATUS_FAILEDTOSENDEMAIL
                        }
                    })
                    .catch(function (error) {
                        SPSiteCommon.log('HTTP:: Response - UserRegistrationManagementService - ContactUs');
                        setLoading(false);
                        SPSiteCommon.log(error);

                        me.setState({
                            disableSend: false
                        });

                        //Generic Failure
                    });
            }
            else {
                this.setState({
                    isVerified: false,
                    disableSend: false
                });
            }
        }
    }

    //Important
    recaptchaOnLoadCallback = () => { }

    recaptchaExpiredCallback = () => {
        this.setState({
            isVerified: false
        });
    }

    recaptchaVerifyCallback = (response) => {
        if (response) {
            this.setState({
                isVerified: true
            });
        }
    }

    render() {
        // const { values } = this.props;
        // const { isVerified, disableSend } = this.state;

        // let disableSendButton = true;
        // if (values.loading === true) {
        //     if (disableSend === false) {
        //         disableSendButton = false;
        //     }
        // }
        // else if (isVerified === true) {
        //     if (disableSend === false) {
        //         disableSendButton = false;
        //     }
        // }

        return (
            <>
                <div style={{ marginTop: -16 }}>
                    <h5>Send us a message</h5>
                    <p>Email us via <a href="mailto:contact@wagglebee.net">contact@wagglebee.net</a></p>
                </div>
            </>
        );
    }
}

export default FeedbackFormView;