import React, { Component } from 'react';

import HeaderStaticView from '../HeaderStaticView';
import FooterView from '../FooterView';
import FeedbackFormView from '../../components/ContactUs/FeedbackFormView';
import FeedbackMessageView from '../../components/ContactUs/FeedbackMessageView';

import { Container } from 'reactstrap';

import contactUsImage from '../../images/contactus1.jpg';

class ContactUsView extends Component {
    state = {
        loading: false,
        step: 1,
        name: '',
        email: '',
        subject: '',
        message: '',
        validateState: {
            emailState: '',
            subjectState: '',
            messageState: ''
        }
    };

    setLoading = flag => {
        this.setState({
            loading: flag
        });
    }

    handleNameChange = name => {
        this.setState({
            name: name
        });
    }

    handleEmailChange = email => {
        this.setState({
            email: email
        });
    }

    handleSubjectChange = subject => {
        this.setState({
            subject: subject
        });
    }

    handleMessageChange = message => {
        this.setState({
            message: message
        });
    }

    updateValidateState = validateState => {
        this.setState({
            validateState: validateState
        });
    }

    gotoStep = step => {
        this.setState({
            step: step
        });
    }

    render() {
        const { step } = this.state;
        const { loading, name, email, subject, message, validateState } = this.state;
        const values = { loading, name, email, subject, message, validateState };

        return (
            <>
                <HeaderStaticView></HeaderStaticView>

                <section className="contactus-section bottom-120">
                    <Container className="contactus-container">
                        <div className="contact-us-image-center">
                            <img src={contactUsImage} className="disable-selection disable-drag" alt="Contact Us" />
                        </div>

                        <div className="contactus-content">
                            {
                                step === 1 ?
                                    <FeedbackFormView
                                        values={values}
                                        setLoading={this.setLoading}
                                        handleNameChange={this.handleNameChange}
                                        handleEmailChange={this.handleEmailChange}
                                        handleSubjectChange={this.handleSubjectChange}
                                        handleMessageChange={this.handleMessageChange}
                                        updateValidateState={this.updateValidateState}
                                        gotoStep={this.gotoStep} /> :
                                    <FeedbackMessageView
                                        values={values} />
                            }
                        </div>
                    </Container>
                </section>

                <FooterView></FooterView>
            </>
        );
    }

}


export default ContactUsView;