import React, { Component } from 'react';
import HeaderStaticView from './HeaderStaticView';
import FooterView from './FooterView';

import { Container, Row, Button } from 'reactstrap';

import { SPSITEMessages } from '../utils/Messages';

import section1Image from '../images/section1.jpg';
import subSectionImage1 from '../images/sub-section-1.jpg';
import subSectionImage2 from '../images/sub-section-2.jpg';
import subSectionImage3 from '../images/sub-section-3.jpg';
import subSectionImage4 from '../images/sub-section-4.jpg';

import card0Image from '../images/card0.jpg';
import card1Image from '../images/card1.jpg';
import card3Image from '../images/card3.jpg';

class HomeView extends Component {
    state = {
        width: window.innerWidth,
        height: window.innerHeight
    };

    componentDidMount = () => {
        window.addEventListener('resize', this.updateDimensions);
    }

    componentWillUnmount = () => {
        window.removeEventListener('resize', this.updateDimensions);
    }

    updateDimensions = () => {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
    };

    render() {
        const { width } = this.state;
        var desktopClass = 'hide-responsive-image';
        var mobileClass = 'hide-responsive-image';

        if (width < 576) {
            desktopClass = 'hide-responsive-image';
            mobileClass = 'show-responsive-image';
        }
        else {
            desktopClass = 'show-responsive-image';
            mobileClass = 'hide-responsive-image';
        }


        return (
            <>
                <HeaderStaticView></HeaderStaticView>

                <section className="first-section">
                    <Container>
                        <Row className="section_1_row">
                            <div className={`col-md-6 ${mobileClass}`} >
                                <img className="section-1-image disable-selection disable-drag" src={section1Image} alt="" ></img>
                            </div>
                            <div className="col-md-6">
                                <div className="section_1_left">
                                    <h1>{SPSITEMessages.SECTION_1_TITLE}</h1>
                                    <p>{SPSITEMessages.SECTION_1_DESCRIPTION_1}</p>
                                    <p>{SPSITEMessages.SECTION_1_DESCRIPTION_2}</p>
                                    <p>{SPSITEMessages.SECTION_1_DESCRIPTION_3}</p>
                                    <Button color="primary" href="/contact-us">Let's Talk</Button>
                                </div>
                            </div>
                            <div className={`col-md-6 ${desktopClass}`}>
                                <img className="section-1-image disable-selection disable-drag" src={section1Image} alt="" ></img>
                            </div>
                        </Row>
                    </Container>
                </section>

                <section className="sub-section">
                    <Container>
                        <Row className="sub_section_row">
                            <div className="col-md-6">
                                <img className="sub_section_image disable-selection disable-drag" src={subSectionImage1} alt="" ></img>
                            </div>
                            <div className="col-md-6">
                                <div className="sub_section_text">
                                    <h2>{SPSITEMessages.SUB_SECTION_1_TITLE}</h2>
                                    <p>{SPSITEMessages.SUB_SECTION_1_DESCRIPTION_1}</p>
                                    <p>{SPSITEMessages.SUB_SECTION_1_DESCRIPTION_2}</p>
                                </div>
                            </div>

                        </Row>
                    </Container>
                </section>

                <section className="sub-section">
                    <Container>
                        <Row className="sub_section_row">
                            <div className={`col-md-6 ${mobileClass}`}>
                                <img className="sub_section_image disable-selection disable-drag" src={subSectionImage2} alt="" ></img>
                            </div>

                            <div className="col-md-6">
                                <div className="sub_section_text">
                                    <h2>{SPSITEMessages.SUB_SECTION_2_TITLE}</h2>
                                    <p>{SPSITEMessages.SUB_SECTION_2_DESCRIPTION_1}</p>
                                    <p>{SPSITEMessages.SUB_SECTION_2_DESCRIPTION_2}</p>
                                </div>
                            </div>

                            <div className={`col-md-6 ${desktopClass}`}>
                                <img className="sub_section_image disable-selection disable-drag" src={subSectionImage2} alt="" ></img>
                            </div>
                        </Row>
                    </Container>
                </section>

                <section className="sub-section">
                    <Container>
                        <Row className="sub_section_row">
                            <div className="col-md-6">
                                <img className="sub_section_image disable-selection disable-drag" src={subSectionImage3} alt="" ></img>
                            </div>
                            <div className="col-md-6">
                                <div className="sub_section_text">
                                    <h2>{SPSITEMessages.SUB_SECTION_3_TITLE}</h2>
                                    <p>{SPSITEMessages.SUB_SECTION_3_DESCRIPTION_1}</p>
                                    <p>{SPSITEMessages.SUB_SECTION_3_DESCRIPTION_2}</p>
                                    <p>{SPSITEMessages.SUB_SECTION_3_DESCRIPTION_3}</p>
                                    <Button color="primary" href="/contact-us">Let's Talk</Button>
                                </div>
                            </div>

                        </Row>
                    </Container>
                </section>

                <section className="sub-section">
                    <Container>
                        <Row className="sub_section_row">
                            <div className={`col-md-6 ${mobileClass}`}>
                                <img className="sub_section_image disable-selection disable-drag" src={subSectionImage4} alt="" ></img>
                            </div>

                            <div className="col-md-6">
                                <div className="sub_section_text">
                                    <h2>{SPSITEMessages.SUB_SECTION_4_TITLE}</h2>
                                    <p>{SPSITEMessages.SUB_SECTION_4_DESCRIPTION}</p>
                                </div>
                            </div>

                            <div className={`col-md-6 ${desktopClass}`}>
                                <img className="sub_section_image disable-selection disable-drag" src={subSectionImage4} alt="" ></img>
                            </div>
                        </Row>
                    </Container>
                </section>

                <section className="bottom-section">
                    <Container>
                        <Row>
                            <div className="col-sm">
                                <div className="card card-height-500">
                                    <img src={card0Image} className="card-img-top disable-selection disable-drag" alt="business card"></img>
                                    <div className="card-body">
                                        <h5 className="card-title">{SPSITEMessages.CARD_0_TITLE}</h5>
                                        <p className="card-text">{SPSITEMessages.CARD_0_DESCRIPTION}</p>
                                    </div>
                                </div>
                            </div>

                            <div className="col-sm">
                                <div className="card card-height-500">
                                    <img src={card1Image} className="card-img-top disable-selection disable-drag" alt="business card"></img>
                                    <div className="card-body">
                                        <h5 className="card-title">{SPSITEMessages.CARD_1_TITLE}</h5>
                                        <p className="card-text">{SPSITEMessages.CARD_1_DESCRIPTION}</p>
                                    </div>
                                </div>
                            </div>

                            <div className="col-sm">
                                <div className="card card-height-500">
                                    <img src={card3Image} className="card-img-top disable-selection disable-drag" alt="business card"></img>
                                    <div className="card-body">
                                        <h5 className="card-title">{SPSITEMessages.CARD_2_TITLE}</h5>
                                        <p className="card-text" dangerouslySetInnerHTML={{ __html: SPSITEMessages.CARD_2_DESCRIPTION }}></p>
                                    </div>
                                </div>
                            </div>
                        </Row>
                    </Container>
                </section>

                <FooterView></FooterView>
            </>
        );
    }
}

export default HomeView;