import { SPSiteConstants } from './Constants';

export const SPSiteCommon = {

    isMobile: function () {
        var flag = false;

        if (navigator.userAgent.match(/Android/i)) {
            flag = true;
        }
        else if (navigator.userAgent.match(/iPhone/i)) {
            flag = true;
        }
        else if (navigator.userAgent.match(/iPad/i)) {
            flag = true;
        }

        return flag;
    },
    isAndroid: function () {
        var flag = false;
        if (navigator.userAgent.match(/Android/i)) {
            flag = true;
        }
        return flag;
    },
    isIPhoneOrIPad: function () {
        var flag = false;
        if (navigator.userAgent.match(/iPhone/i)) {
            flag = true;
        }
        else if (navigator.userAgent.match(/iPad/i)) {
            flag = true;
        }
        return flag;
    },
    isIPad: function () {
        var flag = false;
        if (navigator.userAgent.match(/iPad/i)) {
            flag = true;
        }
        return flag;
    },
    isWindows: function () {
        var flag = false;
        if (navigator.appVersion.indexOf("Win") !== -1) {
            flag = true;
        }
        return flag;
    },
    is64BitWindows: function () {
        var flag = false;
        if (navigator.userAgent.indexOf('x64') !== -1) {
            flag = true;
        }
        return flag;
    },
    isMac: function () {
        var flag = false;
        if (navigator.appVersion.indexOf("Mac") !== -1) {
            flag = true;
        }
        return flag;
    },
    getOSName: function () {
        var osName = 'Unknown OS';

        if (navigator.appVersion.indexOf("Win") !== -1) {
            osName = "Windows";
        }
        else if (navigator.appVersion.indexOf("Mac") !== -1) {
            osName = "MacOS";
        }
        else if (navigator.appVersion.indexOf("X11") !== -1) {
            osName = "UNIX";
        }
        else if (navigator.appVersion.indexOf("Linux") !== -1) {
            osName = "Linux";
        }
        else {
            if (navigator.userAgent.match(/Android/i)) {
                osName = "Android";
            }
            else if (navigator.userAgent.match(/iPhone/i)) {
                osName = "iOS";
            }
            else if (navigator.userAgent.match(/iPad/i)) {
                osName = "iPadOS";
            }
        }

        return osName;
    },
    isPortrait: () => {
        return window.screen.height > window.screen.width;
    },

    log: function (message) {
        if (SPSiteConstants.isTestingBuild === true) {
            console.log(message);
        }
    }

}