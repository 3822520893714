import React, { Component } from 'react';
import { NavLink } from 'reactstrap';

import { SPSITEMessages } from '../utils/Messages';
import brandLogo from '../images/sp_logo_64x64.png';

class FooterView extends Component {

    render() {
        const year = new Date().getFullYear();
        const backgroundStyle = { backgroundImage: `url(${brandLogo})` };

        return (
            <>
                <footer className="footer-area">
                    <div className="container">
                        <div className="row">

                            <div className="col-sm justify-content-center footer-column">
                                <h6>ABOUT</h6>
                                <NavLink href="/contact-us" className="no_pading_left">Contact Us</NavLink>
                            </div>

                            <div className="col-sm footer-column">
                                <h6>COMPANY</h6>
                                <p>{SPSITEMessages.FOOTER_COMPANY_SHORT_INFO}</p>
                            </div>

                        </div>
                    </div>
                </footer>
                <div className="footer-area2">
                    <div className="container">
                        <div className="row">
                            <div className="footer-small-logo" style={backgroundStyle}></div>
                            <div className="center_text">Copyright © {year} Stillpoint Software, Inc.</div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default FooterView;