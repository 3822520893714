const StringFormat = require("string-template");

export const SPSiteConstants = {
    Version: '1.0.7',
    Build: '8',
    BuildDate: '2021-03-22 10:45',
    AppName: 'wagglebeeappweb',
    ServiceVersion: '1.0',
    Secured: true,
    isTestingBuild: false, //false disable for production builds
    ServerBase: 'api.wagglebeeapp.com', //api.wagglebeeapp.com //api.wagglebeeapp.dev
    DownloadLinks: {
        ANDROID: 'https://play.google.com/store/apps/details?id=com.sp.Wagglebee&utm_source=global_co&utm_medium=prtnr&utm_content=Mar2515&utm_campaign=PartBadge&pcampaignid=MKT-AC-global-none-all-co-pr-py-PartBadges-Oct1515-1',
        IPHONE: 'https://itunes.apple.com/us/app/wagglebee/id1082746389?mt=8',
        WINDOWS32: 'https://api.wagglebeeapp.com/wagglebeeappweb/1.0/AppUpdaterService/DownloadDesktopApp?platform=win32',
        WINDOWS64: 'https://api.wagglebeeapp.com/wagglebeeappweb/1.0/AppUpdaterService/DownloadDesktopApp?platform=win64',
        MAC: 'https://api.wagglebeeapp.com/wagglebeeappweb/1.0/AppUpdaterService/DownloadDesktopApp?platform=mac'
    },
    GOOGLE_RECAPTCHA_SITE_KEY_V2: '6LdIrdsUAAAAABHwdg83t1pXhcHbWje6N1jCxizg', //Dev: 6Lf2ztcUAAAAAEou8Xd_VCVuMhwnL6oRAVryNo_J //Production: 6LdIrdsUAAAAABHwdg83t1pXhcHbWje6N1jCxizg

    getServerUrl: function () {
        const webProtocol = this.Secured ? 'https' : 'http';
        return StringFormat('{0}://{1}/{2}/{3}', webProtocol, this.ServerBase, this.AppName, this.ServiceVersion);
    },

    getRegisterUserEmailUrl: function () {
        return StringFormat('{0}/UserRegistrationManagementService/RegisterUserEmail', this.getServerUrl());
    },

    getVerifyUserEmailUrl: function () {
        return StringFormat('{0}/UserRegistrationManagementService/VerifyUserEmail', this.getServerUrl());
    },

    getRegisterWithHiveUrl: function () {
        return StringFormat('{0}/UserRegistrationManagementService/RegisterWithHive', this.getServerUrl());
    },

    getContactUsUrl: function () {
        return StringFormat('{0}/UserRegistrationManagementService/ContactUs', this.getServerUrl());
    },

    getServiceAvailabilityUrl: function () {
        return StringFormat('{0}/AuthenticationService/Ping', this.getServerUrl());
    }

}