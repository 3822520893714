export const SPSITEMessages = {
    SECTION_1_TITLE: `Startup thinking at Enterprise scale`,
    SECTION_1_DESCRIPTION_1: `We are a product and development consultancy. We work with forward-thinking teams to improve actionability with their products, customers, partners, and one another.`,
    SECTION_1_DESCRIPTION_2: `We provide a full range of software development services, and technology, for startups and enterprises that want to move quickly. We specialize in solving complex productization and development problems across industries, including healthcare, telecommunications, financial services, and media companies.`,
    SECTION_1_DESCRIPTION_3: `If you want to improve your organizations technical agility, we want to talk.`,

    SUB_SECTION_1_TITLE: `Be Relevant - Act`,
    SUB_SECTION_1_DESCRIPTION_1: `Companies that can't keep up quickly fall out of touch. When your systems get in the way your best people will either find creative ways to circumvent them or they'll leave. This creates deep security and compliance concerns on the one hand while risking your best customers, employees, and partners on the other.`,
    SUB_SECTION_1_DESCRIPTION_2: `The pathway to relevance, and the ability to innovate, is to leverage the human communication networks within your organization. Free people to innovate while also driving measurable action through a communications first strategy.`,

    SUB_SECTION_2_TITLE: `Use Your Stuff`,
    SUB_SECTION_2_DESCRIPTION_1: `You already have the systems; in-fact you probably have dozens of them. What if you had the ability to creatively recombine and reuse your existing IT investments to address new opportunity, while being mindful of compliance and data security?`,
    SUB_SECTION_2_DESCRIPTION_2: `We build software that lets you integrate your business data and back-end systems directly into your communications flow and to quickly integrate with partners and vendors.`,

    SUB_SECTION_3_TITLE: `Let's Make a Plan`,
    SUB_SECTION_3_DESCRIPTION_1: `Discover → Propose → Design → Engineer`,
    SUB_SECTION_3_DESCRIPTION_2: `Relevance is about doing the right-thing, at the right-time, in the right-way. It is about effecting action and measuring results.`,
    SUB_SECTION_3_DESCRIPTION_3: `We are experts at mapping the processes necessary to design, implement, and deploy successful initiatives that make your interactions more meaningful; from discovery and ideation, through turn-up, analytics, and operation.`,

    SUB_SECTION_4_TITLE: `Our Technology`,
    SUB_SECTION_4_DESCRIPTION: `We have out-of-the-box solutions for collaboration, rules, meta-data, integrations, security, deployment, and continuous integration that allow you to focus your resources on what matters. Our technology stacks and APIs will get you to MVP faster on a foundation that will grow with you.`,

    CARD_0_TITLE: `Scrum-As-A-Service`,
    CARD_0_DESCRIPTION: `We work with teams to take your products, cultures, and processes to where you want to be next. We focus on improving transparency and delivering quick, iterative value.`,

    CARD_1_TITLE: `Workshops & Assessments`,
    CARD_1_DESCRIPTION: `We workshop with stakeholders and teams to map and assess current state. From this we work with you to create a roadmap that will realize your mission/vision.`,

    CARD_2_TITLE: `Technology & Stack`,
    CARD_2_DESCRIPTION: `We bring existing stack that solves crucial scale-out concerns, so that you can start with your solution, faster. Good bones mean getting to Minimum Viable without sacrificing the future.`,

    CARD_3_TITLE: `Compliance & Security`,
    CARD_3_DESCRIPTION: `Your business' content security is a top priority. We are experts in building technology solutions that ensure that your system honors information security and compliance best practices.`,

    FOOTER_COMPANY_SHORT_INFO: `We are nerds when it comes to software. Our mission is to address the B2B2C communication gap, across all systems and devices, to reach more people more effectively, both inside and outside the enterprise.`,

    PAGE_NOT_FOUND_TITLE: 'OOops !!!',
    PAGE_NOT_FOUND_DESCRIPTION1: 'The page you are looking for is not available.',
    PAGE_NOT_FOUND_DESCRIPTION2: 'Click below button to go to home',

    MAINTENANCE_TITLE: 'Scheduled Maintenance',
    MAINTENANCE_DESCRIPTION1: 'Our website is undergoing scheduled maintenance.',
    MAINTENANCE_DESCRIPTION2: 'We will be back soon.',

    EMAIL_INPUT_ERROR: 'Oops! It looks like there is an issue with your email address.',
    NAME_INPUT_ERROR: 'Enter your first and last name.',
    HIVESHORTNAME_INPUT_ERROR: 'Enter a hive short name.',
    HIVESHORTNAME_INPUT_ERROR_HIVENOTFOUND: 'Hive short name not found.',

    SUBJECT_INPUT_ERROR: 'Enter subject of your message',
    MESSAGE_INPUT_ERROR: 'Enter content of your message'
}