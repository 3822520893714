import React, { Component } from 'react';

import HeaderStaticView from '../HeaderStaticView';
import HeaderRegistrationView from '../HeaderRegistrationView';
import FooterView from '../FooterView';

import { SPSiteCommon } from '../../utils/Common';

import { Container } from 'reactstrap';

class TermsOfUseView extends Component {

    render() {
        let mobile = SPSiteCommon.isMobile();
        return (
            <>
                {
                    mobile === true ? <HeaderRegistrationView></HeaderRegistrationView> :
                        <HeaderStaticView></HeaderStaticView>
                }

                <Container className="bottom-32">
                    <p className="text-align-right top-16">Last Modified: February 11, 2016</p>
                    <p className="text-align-center"><h5>Wagglebee Terms of Service</h5></p>
                    <p className="text-uppercase">These wagglebee terms of service cover all uses of our services, web site and technology, including all content, information and data available thereon, from and after the date set forth above.  Your acceptance of these terms of service or your use or continued use of our services, web site and technology, whichever comes first, constitutes your irrevocable acceptance of these terms of service.  We may change these terms of service at any time and from time to time in our sole discretion and you agree to access and read the terms from time to time to ensure that you understand the rules that apply to your use of our services.</p>
                    <p><b>Our Services are intended and shall only be accessed for personal, non-commercial purposes unless and until a user enters into a paid subscription agreement with us.  Unpaid users must be individuals (not business entities) making personal (non-business) use of the Services.</b></p>
                    <p>These Wagglebee Terms of Service constitute a legally binding agreement that you must accept in order to use Wagglebee’s services, web site and/or technology.  Please read these terms carefully before using any of our Services (as defined below).</p>
                    <p><h5>1. ACCEPTING THESE TERMS OF SERVICE</h5></p>
                    <p>Please read these Terms of Service and the Wagglebee Privacy Policy posted on our web site (collectively, the <b>"Agreement"</b>) before using the Wagglebee services, web site or technology or any other products, applications, mobile applications, services and/or Content provided by us (collectively, the <b>"Services"</b>).  Wagglebee is owned by STILLPOINT SOFTWARE INC., a Virginia corporation, which together with its officers, directors, employees, agents and representatives is referred to herein as <b>"Wagglebee"</b>, <b>"we"</b> or <b>"us"</b>.  By using or accessing the Services, you (the <b>"Subscriber"</b>, <b>"you"</b> or <b>“your”</b>) agree to be bound by all of the terms and conditions of this Agreement.  If you don’t agree to all the terms and conditions of this Agreement, you are not permitted to use the Services.</p>
                    <p>If you have entered into a paid subscription agreement and are accepting these terms on behalf of a company, business or other form of organization, whether for-profit or non-profit, you hereby represent and warrant that you are authorized to bind such entity to these terms.  In that case, the terms “Subscriber,” you” or “your” shall also refer to such entity.  If you do not have such authority or if the represented business or entity does not agree to all of these terms, you may not use Wagglebee’s Services.</p>
                    <p><h5>2. MODIFICATIONS TO THIS AGREEMENT</h5></p>
                    <p>Wagglebee reserves the right to modify this Agreement by (a) posting a revised Agreement on and/or through the Services (including on the web site), and (b) providing notice to you that this Agreement has changed, generally via email where practicable, and otherwise through the Services.  Modifications will not apply retroactively.  You are responsible for reviewing and understanding any modifications to this Agreement promptly after receiving notice thereof, either directly or indirectly through accessing the Services after such modifications are posted.</p>
                    <p>We may sometimes ask you to review and to explicitly agree to, or refuse to agree to, a revised version of this Agreement.  In such cases, modifications will be effective at the time of your agreement to the modified version of this Agreement. Should you reject the modifications, then you must immediately terminate your use of the Services.</p>
                    <p>If we do not ask for your explicit agreement to any modifications to this Agreement, the modified version of this Agreement will become effective immediately after we have posted the modified Agreement and provided you notification of the modifications.  Your use of the Services following that period constitutes your acceptance of the modified terms and conditions.  As noted above, should you not agree to the modifications, then you must immediately terminate your use of the Services.</p>
                    <p><h5>3. USE OF THE SERVICES</h5></p>
                    <p><b>No Use by Children:</b></p>
                    <p>No individual under the age of thirteen (13) may use the Services, provide any personal information to Wagglebee or otherwise submit personal information through the Services (including, for example, a name, address, telephone number or email address).  You may only use the Services if you can form a binding contract with Wagglebee and are not legally prohibited from using the Services.  Children under 13 years of age may not subscribe to or use the Services.  Therefore their use of our Services is prohibited.</p>
                    <p><b>Changes in our Services:</b></p>
                    <p>Our Services are in an ongoing state of upgrade, refinement and improvement and thus may change in functionality and capability on a frequent basis, usually without any prior notice to you.  We reserve the right to create limits or place restrictions on or about the Services and the users thereof, in our discretion, at any time with or without notice.  We may also impose limits on certain Services or aspects of those Services or restrict your access to parts or all of the Services without notice or liability.  We may change, suspend or discontinue any or all of the Services at any time, including the availability of any product, feature, database or Content (as defined below).  We may also terminate or suspend Accounts (as defined below) at any time, in our sole discretion.</p>
                    <p><b>Limitations on Scraping, Invasive or Automated Use:</b></p>
                    <p>You may not, without our prior written consent, do any of the following while accessing or using the Services: (a) tamper with, invade, access or use internal (non-publicly available) areas of this site or any of the internal architecture (i.e., code, data, etc.) of the Services (directly or indirectly through bots), or the computer or delivery systems of Wagglebee and/or its service providers; (b) probe, scan, or test any system or network (particularly for vulnerabilities), or otherwise attempt to breach or circumvent any security or authentication measures; (c) access or search or attempt to access or search the Services by any means (automated or otherwise) other than through our currently available, published interfaces that are provided by Wagglebee (and only pursuant to those terms and conditions) or unless permitted by Wagglebee's robots.txt file or other robot exclusion mechanisms; (d) scrape the Services and/or scrape any Content (as defined in <u>Section 6</u> below) from the Services; (e) use the Services to send altered, deceptive, or false source-identifying information, including without limitation by forging TCP-IP packet headers or email headers; or (f) interfere with, or disrupt, (or attempt to do so), the access of any Subscriber, host or network, including, without limitation, by sending a virus to, spamming, or overloading the Services, or by scripted use of the Services in such a manner as to interfere with or create an undue burden on the Services.</p>
                    <p><h5>4. REGISTRATION, URLS AND SECURITY</h5></p>
                    <p>As a condition to using certain of the Services, you may be required to create an account (an <b>"Account"</b>) and select a password and Wagglebee username, which will serve as a default link to your default Wagglebee home page, in the form of “[username].wagglebee.net” (a <b>"Wagglebee URL"</b>).  You must select a different Wagglebee URL for each new blog you create and publish.</p>
                    <p>You agree to provide Wagglebee with accurate, complete, and updated registration information, particularly your email address.</p>
                    <p className="bg-gray">It's really important that the email address associated with your Wagglebee account is accurate and up-to-date. If you ever forget your password - or worse, fall victim to a malicious phishing attack - a working email address is often the only way for us to recover your account.</p>
                    <p>You are also responsible for maintaining the confidentiality of your Account password and for the security of your Account, and you will notify Wagglebee immediately of any actual or suspected loss, theft, or unauthorized use of your Account or Account password.</p>
                    <p><h5>5. PRIVACY</h5></p>
                    <p>For information about how Wagglebee collects, uses, and shares your information, please review our Privacy Policy. You agree that by using the Services you consent to the collection, use, and sharing (as set forth in the <u>Privacy Policy</u>) of such information, including the transfer of this information to the United States and/or other countries for storage, processing, and use by Wagglebee and the Wagglebee Affiliates (as defined below).</p>
                    <p><h5>6. CONTENT AND SUBSCRIBER CONTENT</h5></p>
                    <p><b>Definitions:</b></p>
                    <p>For purposes of this Agreement: (i) the term <b>"Content"</b> means a creative expression and includes, without limitation, video, audio, photographs, images, illustrations, animations, logos, tools, written posts, replies, comments, information, data, text, software, scripts, executable files, graphics and/or interactive features, any of which may be generated, provided or otherwise made accessible on or through the Services; and (ii) the term <b>"Subscriber Content"</b> means Content that a Subscriber submits, transfers or otherwise provides to or through the Services.  Content includes, without limitation, all Subscriber Content.</p>
                    <p><b>Your Rights in Subscriber Content:</b></p>
                    <p>Subscribers retain ownership or other applicable rights in any and all Subscriber Content.  We and/or our licensees retain ownership or other applicable rights in all Content other than Subscriber Content.</p>
                    <p><b>Subscriber Content License to Us:</b></p>
                    <p>When you provide Subscriber Content to us through the Services, you hereby grant to Stillpoint Software Inc., operating by and through this Wagglebee site, our Services and our mobile app, an irrevocable, non-exclusive, worldwide, royalty-free, full sub-licensable and fully transferable right and license to use, host, store, cache, reproduce, publish, display (publicly or otherwise), perform (publicly or otherwise), distribute, transmit, modify, adapt (including, without limitation, in order to conform it to the requirements of any networks, devices, services, or media through which the Services are available), and create derivative works of any publicly published Subscriber Content (the <b>“Subscriber Content License”</b>).  The rights you grant in this license are necessary for us to operate the Services in accordance with their functionality, improve the Services and develop new Services.  The reference in this license to "create derivative works" will not be exercised by us to make substantive editorial changes or derivations to the Subscriber Content, but rather, for example, permits us to enable reposting, which allows our Subscribers to redistribute publicly published Subscriber Content from one Wagglebee blog to another in a manner that allows them to add their own text or other Content before or after the Subscriber Content.</p>
                    <p><b><u>This policy is worth repeating:</u>  We will not create derivative works of or publish any non-publicly published (i.e., private) Subscriber Content, consistent with our Privacy Policy posted on this site.  WHAT IS PUBLIC IS PUBLIC BUT WHAT IS PRIVATE SHALL REMAIN PRIVATE.  That said, we reserve the right to make use of non-personally identifying data in an aggregate (non-traceable) form.</b></p>
                    <p>You also hereby acknowledge and agree that the Subscriber Content License includes our right to make all publicly-posted Content available to third parties selected by us, so that those third parties can syndicate and/or analyze such Content on other media and services.  For example, this would apply to any Wagglebee publicly available Swarm or a live feed of all public activity on Wagglebee to partners like search engines.</p>
                    <p>You should also be aware that the Subscriber Content License survives and continues even if you stop using the Services, primarily because of the social nature of Content that is publicly shared through Wagglebee’s Services.  That is, when you post something publicly, others may choose to comment on it, making your Content part of a social conversation that can’t later be erased without retroactively censoring the speech of others. <b>So when you make something publicly available on the Internet, you should assume that it is practically impossible for you to delete or for us to take down all copies of it.</b></p>
                    <p>You hereby represent and warrant that you will respect the intellectual property rights of us and others and that you have all of the necessary rights to grant us the Subscriber Content License as defined above and all rights and interests incident thereto.</p>
                    <p><b>Your Content License:</b></p>
                    <p>As a Subscriber of the Services, we hereby grant to you a worldwide, revocable, non-exclusive, non-sublicensable and non-transferable license, during the period that you are an active and authorized Subscriber in good standing (i.e., you have fully paid for your commercial use), to download, store, view, display, perform, redistribute and create derivative works of publicly shared Content solely in connection with your use of, and in strict accordance with, the functionality and restrictions of, the Services (<b>“Content License”</b>).  Termination of your account, regardless of cause, shall terminate your Content License.  Among other things, the Content License covers your reposting of Content.</p>
                    <p><b>Termination and Deletion of Account:</b></p>
                    <p><h5>7. USE OF TRADEMARKS</h5></p>
                    <p>Any use of our trademarks, branding, logos, look-and-feel and/or other such rights and interests incorporated into or used in connection with the Services (<b>“Wagglebee Trademarks”</b>) constitute a material term of this Agreement.  All <b>Wagglebee Trademarks</b> are subject to our <u>Wagglebee Trademark Guidelines</u> and may not ever be used without our explicit written consent.  Any violation of such guidelines and/or non-sanctioned use shall constitute a material breach of this Agreement.</p>
                    <p><h5>8. PAID SERVICES</h5></p>
                    <p>Some of the Services require payment of fees (<b>"Paid Services"</b>), which include but are not limited to Premium Swarms and Services.  All fees are stated in and payable only in U.S. dollars.  You shall pay all applicable fees associated with any and all Services used by you and any and all related taxes or additional charges.</p>
                    <p>Paid Services are subject to limited licenses for your use of particular “for-pay” Services or functionalities.  Our use of terms like “sell, sale, buy, purchase” or similar terms do not refer to your acquisition of ownership or title to the Services.  They all refer to your acquisition of a license to use Paid Services.  Therefore, your subscription for paid services do not represent any transfer to you of any right, title, or ownership interest of any kind, but rather, a usage right consent with these terms.  You may not relicense, resell, transfer or exchange Paid Services within or outside of the Services, except as expressly allowed by the rules of those Paid Services.</p>
                    <p>Wagglebee may, in its sole discretion, modify the functionality of or eliminate any one or more Paid Services and/or the terms and conditions under which any Paid Services are provided.</p>
                    <p>Purchases of Paid Services are final and non-refundable, except at our sole discretion and in accordance with the rules governing each Paid Service.  Termination of your Account or your rights under this Agreement may result in forfeiture of purchased or prepaid Paid Services.  For example, if your Account is suspended, you forfeit your license to any Premium Services you have purchased.</p>
                    <p>We may change the prices for Paid Services at any time in our discretion with or without notice to you.  Nevertheless, we endeavor, to the extent practicable, to provide reasonable notice of any such pricing changes by posting the new prices on or through the applicable Paid Service and/or by sending you an email notification.  If you do not wish to pay the new prices, you may choose not to purchase, or to cancel the applicable Paid Service, prior to the change going into effect.</p>
                    <p><h5>9. TERMS OF PROMOTIONS</h5></p>
                    <p>Some features of the Services may allow you to promote yourself to other Subscribers and some of these features may be Paid Services (<b>"Promotions"</b>).</p>
                    <p>The following additional terms apply to Promotions:</p>
                    <p>
                        <ul>
                            <li>Things that you promote will comply with this Agreement; if they do not, we reserve the right, in our sole discretion, to cancel a Promotion without refund or recourse to you.</li>
                            <li>We may remove or disable any Promotion for any reason in our sole discretion.</li>
                            <li>Unless otherwise specified, we do not guarantee any activity that Promotions may receive, including but not limited to clicks, likes, reposts, and shares.  We cannot control how Subscribers interact with Promotions and we are not responsible for "click fraud" or other fraudulent actions by third parties, including, without limitation, Subscribers.  We do not guarantee that the reporting related to Promotions will be accurate or complete, nor do we guarantee that Promotions will behave in a particular manner.  We shall not be liable to you or responsible for any erroneous reporting about or errant behavior of or related to Promotions (e.g., errors in how Likes or reposts are counted).</li>
                            <li>Promotions may allow targeting to some Subscriber characteristics.  We do not guarantee that any particular Promotion will reach a particular sort of individual in any case or a given class of individuals in all cases.</li>
                            <li>You can cancel certain Promotions at any time depending on their functionality, but you are responsible for paying for Promotions to the extent they have been used or distributed through the Services for up to forty-eight (48) hours after your cancellation.</li>
                            <li>By submitting a Promotion, you license Wagglebee to run that Promotion for as long as you have specified, which may be perpetually.  This license ends when the Promotion has been completed or cancelled, but it may take up to forty eight (48) hours for a Promotion to stop being reflected on the Services.</li>
                        </ul>
                    </p>
                    <p><h5>10. WARRANTY DISCLAIMER; SERVICES AVAILABLE ON AN "AS IS" BASIS</h5></p>
                    <p>Your access to and use of the Services or any Content is at your own risk.  YOU UNDERSTAND AND AGREE THAT THE SERVICES ARE PROVIDED TO YOU ON AN "AS IS" AND "AS AVAILABLE" BASIS.  WITHOUT LIMITING THE FOREGOING, TO THE FULL EXTENT PERMITTED BY LAW, WAGGLEBEE DISCLAIMS ALL WARRANTIES, EXPRESS OR IMPLIED, OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, NON-INFRINGEMENT AND SYSTEM AVAILABILITY. Wagglebee makes no representations or warranties of any kind with respect to the Services, including any representation or warranty that the use of the Services will (a) be timely, uninterrupted or error-free or operate in combination with any other hardware, software, system, or data, (b) meet your requirements or expectations, (c) be free from errors or that defects will be corrected, or (d) be free of viruses or other harmful components. Wagglebee also makes no representations or warranties of any kind with respect to Content.  All Subscriber Content is provided by and is solely the responsibility of the Subscribers providing that Content.  No advice or information, whether oral or written, obtained from Wagglebee or through the Services shall create any warranty not expressly made herein.</p>
                    <p><h5>11. TIME LIMITATION ON CLAIMS AND RELEASES FROM LIABILITY</h5></p>
                    <p>You agree that any claim you may have arising out of or related to this Agreement or your relationship with Wagglebee must be filed with us in writing within one (1) year after such claim has arisen or accrued, regardless of whether you had actual notice that such claim had arisen or accrued.  Unless so filed in such period, any such claim against us is permanently and irrevocably barred.</p>
                    <p className="text-uppercase">You further waive and release, to the fullest extent permitted by law, stillpoint software inc. And its officers, directors, employees, agents, representatives, shareholders and affiliates from and against all responsibility, liability, claims, demands, and/or damages (actual and consequential) of every kind and nature, known and unknown (including but not limited to claims of negligence), arising out of or related to the following:</p>
                    <p>
                        <ul>
                            <li>Disputes between Subscribers, including those between you and other Subscribers.</li>
                            <li>The acts of third parties generally (i.e., individuals and entities other than Wagglebee and its affiliates), including third party sites and services.</li>
                            <li>Disputes concerning any use of or action taken using your Account by you or a third party.</li>
                            <li>Claims relating to the unauthorized access to any data communications relating to, or Content stored under or relating to, your Account, including but not limited to, unauthorized interception, use, or alteration of such communications or your Content.  For the sake of clarity, this includes any and all claims related to the security of your Account credentials.</li>
                            <li>Claims relating to any face-to-face meetings in any way related to Wagglebee at any venues (<b>"Meetups"</b>), including without limitation, claims related to the actions or omissions of any Subscribers or third parties who organize, attend, or are otherwise involved in any Meetups.  Unless otherwise expressly disclosed in writing, Wagglebee does not sponsor, oversee, or in any manner control Meetups.</li>
                        </ul>
                    </p>
                    <p><b>If you are a California resident, you waive California Civil Code § 1542, which says: "A general release does not extend to claims which the creditor does not know or suspect to exist in his favor at the time of executing the release, which if known by him must have materially affected his settlement with the debtor."</b></p>
                    <p><h5>12. LIMITATION OF LIABILITY</h5></p>
                    <p className="text-uppercase">To the maximum extent permitted by applicable law, stillpoint software inc. And its successors, assigns and affiliates shall not be liable for:  (a) any indirect, incidental, exemplary punitive, or consequential damages of any kind whatsoever; (b) loss of profits, revenue, data, use, goodwill, or other intangible losses; (c) damages relating to your access to, use of, or inability to access or use the services; (d) damages relating to any conduct or content of any third party or subscriber using the services, including without limitation, defamatory, offensive or illegal conduct or content; and/or (e) damages in any manner relating to any content.  This limitation applies to all claims, whether based on warranty, contract, tort, or any other legal theory, whether or not we have been informed of the possibility of such damage, and further where a remedy set forth herein is found to have failed its essential purpose.</p>
                    <p className="text-uppercase">To the maximum extent permitted by applicable law, the total liability of stillpoint software inc. And its successors, assigns and affiliates, for any claim under this agreement, including for any implied warranties, is limited to the lesser of one thousand dollars (us $1000.00) and the amount you paid us to use the applicable service(s) on which the claim is based.</p>
                    <p><h5>13. TERMINATION</h5></p>
                    <p>Either party may terminate this Agreement at any time by notifying the other party in writing (including electronic notice).  We may terminate or suspend your access to or ability to use any and all Services immediately, without prior notice or liability, for any reason or no reason, including but not limited to if you breach any of the terms or conditions of this Agreement.  In particular, we may immediately terminate or suspend Accounts that have been flagged for repeat copyright infringement.</p>
                    <p>Upon termination of your access to or ability to use a Service, including but not limited to suspension of your Account on a Service, your right to use or access that Service and any Content will immediately cease.  All provisions of this Agreement that by their nature should survive termination shall survive termination, including, without limitation, ownership provisions, warranty disclaimers and limitations of liability.  Termination of your access to and use of the Services shall not relieve you of any obligations arising or accruing prior to such termination or limit any liability that you otherwise may have to us or to any third party.</p>
                    <p><h5>14. CHOICE OF LAW AND VENUE</h5></p>
                    <p>You and we each agree that we will resolve any claim or controversy at law or equity that arises out of this Agreement or the Services in accordance with this Section or as you and we may otherwise agree in writing.  Before resorting to formal dispute resolution, we strongly encourage you to contact us to seek a resolution.</p>
                    <p><b>Law and Forum for Legal Disputes:</b></p>
                    <p>This Agreement shall be governed in all respects by the laws of the Commonwealth of Virginia as they apply to agreements entered into and to be performed entirely within Virginia by and between Virginia residents, without regard to conflict of law provisions.  You agree that any claim or dispute you may have against us must be resolved exclusively by a state or federal court located in Loudoun County, Virginia, except as otherwise agreed by the parties.  YOU WAIVE ANY RIGHT YOU MAY HAVE TO A TRIAL BY JURY IN ANY SUCH ACTION OR PROCEEDING.  You agree to submit to the personal jurisdiction of the courts located within Loudoun County, Virginia for the purpose of litigating all such claims or disputes.</p>
                    <p><h5>15. MISCELLANEOUS</h5></p>
                    <p>This Agreement, as modified from time to time, constitutes the entire agreement between you and Stillpoint Software Inc. with respect to the subject matter hereof.  This Agreement replaces all prior or contemporaneous understandings or agreements, written or oral, regarding the subject matter hereof and constitutes the entire and exclusive agreement between us.  The failure of either party to exercise, in any way, any right provided for herein shall not be deemed a waiver of any further rights hereunder.  If any provision of this Agreement is found to be unenforceable or invalid, that provision shall be limited or eliminated to the minimum extent necessary so that this Agreement shall otherwise remain enforceable and in full force and effect.  This Agreement is not assignable, transferable, or sub-licensable by you except with our prior written consent.  We may assign this Agreement in whole or in part at any time without your consent.  No agency, partnership, joint venture, or employment is created as a result of this Agreement and you do not have any authority of any kind to bind Stillpoint Software Inc. in any respect whatsoever.  Any notice to us that is required or permitted by this Agreement shall be in writing and shall be deemed effective upon receipt, when delivered in person by nationally recognized overnight courier or mailed by first class, registered or certified mail, postage prepaid, to Stillpoint Software Inc., P.O.BOX 418, WASHINGTON VA, 22747 USA, Attn: Legal Department.</p>
                    <p><h5>16. FOR ANY NON-U.S. BASED SUBSCRIBERS</h5></p>
                    <p>Wagglebee provides global products and services and enables a global community for individuals to share and follow the things they love.  Wagglebee's servers and operations are, however, located in the United States, and Wagglebee's policies and procedures are based on United States law.  As such, for any and all Subscribers located outside of the United States, you expressly consent to the transfer, storage, and processing of your information and data, including but not limited to Subscriber Content and any personal information, to and in the United States and/or other countries.</p>
                    <p><h5>17. DMCA COPYRIGHT POLICY</h5></p>
                    <p>Stillpoint Software Inc. has adopted the following policy toward copyright infringement on the Services in accordance with the <u>Digital Millennium Copyright Act</u> (the <b>"DMCA"</b>).  The address of our Designated Agent for copyright takedown notices (<b>"Designated Agent"</b>) is listed below.</p>
                    <p><b>Reporting Instances of Copyright Infringement:</b></p>
                    <p>If you believe that Content residing or accessible on or through the Services infringes a copyright, please send a notice of copyright infringement containing the following information to the Designated Agent at the address below:</p>
                    <p>
                        <ol>
                            <li>Identification of the work or material being infringed.</li>
                            <li>Identification of the material that is claimed to be infringing, including its location, with sufficient detail so that we are capable of finding it and verifying its existence.</li>
                            <li>Contact information for the notifying party (the "Notifying Party"), including name, address, telephone number, and email address.</li>
                            <li>A statement that the Notifying Party has a good faith belief that the material is not authorized by the copyright owner, its agent or law.</li>
                            <li>A statement made under penalty of perjury that the information provided in the notice is accurate and that the Notifying Party is authorized to make the complaint on behalf of the copyright owner.</li>
                            <li>A physical or electronic signature of a person authorized to act on behalf of the owner of the copyright that has been allegedly infringed.</li>
                        </ol>
                    </p>
                    <p>Please also note that the information provided in a notice of copyright infringement may be forwarded to the Subscriber who posted the allegedly infringing content.  After removing material pursuant to a valid DMCA notice, we will immediately notify the Subscriber responsible for the allegedly infringing material that we have removed or disabled access to the material.  We will terminate, under appropriate circumstances, the Accounts of Subscribers who are repeat copyright infringers, and we reserve the right, in our sole discretion, to terminate any Subscriber for actual or apparent copyright infringement.</p>
                    <p><b>Submitting a DMCA Counter-Notification:</b></p>
                    <p>If you believe you are the wrongful subject of a DMCA notification, you may file a counter-notification with us by providing the following information to the Designated Agent at the address below:</p>
                    <p>
                        <ul>
                            <li>The specific URLs of material that we have removed or to which we have disabled access.</li>
                            <li>Your name, address, telephone number and email address.</li>
                            <li>A statement that you consent to the jurisdiction of Federal District Court for the judicial district in which your address is located (or the federal district courts located in Loudoun County, Virginia if your address is outside of the United States), and that you will accept service of process from the person who provided the original DMCA notification or an agent of such person.</li>
                            <li>The following statement: "I swear, under penalty of perjury, that I have a good faith belief that the material was removed or disabled as a result of a mistake or misidentification of the material to be removed or disabled."</li>
                            <li>Your signature.</li>
                        </ul>
                    </p>
                    <p>Upon receipt of a valid counter-notification, we will forward it to Notifying Party who submitted the original DMCA notification.  The original Notifying Party (or the copyright holder he or she represents) will then have ten (10) days to notify us that he or she has filed legal action relating to the allegedly infringing material.  If we do not receive any such notification within ten (10) days, we may restore the material to the Services.</p>
                    <p><b>Designated Agent</b></p>
                    <p>Stillpoint Software Inc.<><br/></>
                        P.O.BOX 418<><br /></>
                        WASHINGTON, VA 22747, USA</p>
                    <p><b>Attn:</b> DCMA Copyright Agent</p>
                    <p><b>Email:</b> <a href="mailto:dmca@wagglebee.net">dmca@wagglebee.net</a></p>
                    <p><b>Subject:</b> DCMA copyright takedown notice</p>
                </Container>

                <FooterView></FooterView>
            </>
        );
    }
}

export default TermsOfUseView;