import React, { Component } from 'react';

import HeaderStaticView from '../HeaderStaticView';
import HeaderRegistrationView from '../HeaderRegistrationView';
import FooterView from '../FooterView';

import { SPSiteCommon } from '../../utils/Common';

import { Container } from 'reactstrap';

class PrivacyPolicyView extends Component {

    render() {
        let mobile = SPSiteCommon.isMobile();
        return (
            <>
                {
                    mobile === true ? <HeaderRegistrationView></HeaderRegistrationView> :
                        <HeaderStaticView></HeaderStaticView>
                }


                <Container className="bottom-32">
                    <p className="text-align-right top-16">Last Modified: February 11, 2016</p>
                    <h5 className="text-align-center">Wagglebee Privacy Policy</h5>
                    <p className="text-uppercase">This privacy policy covers all uses of our services, site and technology, including all content, information and data available thereon, from and after the date set forth above.  Your use of our services, site and technology constitutes your acceptance to the terms of this privacy policy.  We may change this policy at any time and from time to time in our sole discretion and you agree to access and read this policy from time to time to ensure that you understand the terms applicable to your use of our services.</p>
                    <p>Wagglebee is owned by Stillpoint Software Inc, a Virginia (USA) corporation, and the use of our Services, site and technology is subject, solely and exclusively, to U.S. laws and jurisdiction.  As a result, this policy (and all our other terms, policies and guidelines) are only available in English.</p>
                    <h5>What We Do</h5>
                    <p>Wagglebee is cloud based software which provides sophisticated and easy-to-operate activity based communications to individuals and organizations of any size.  Further, it allows people to organize their activities and to store and share data, publicly or privately, based on their individual requirements and personal preferences.</p>
                    <p>Wagglebee also allows users to maintain different characters or personalities, which we call “personas,” for different elements of their life (i.e., personal, work, family, etc.) and to organize their activities and control what they share and who they share it with based on these profiles.  This avoids clutter, promotes easy communications and enables the establishment of specific personal privacy controls.  Wagglebee also allows businesses, enterprises, and small groups to create their own customized activities for both private and community use, and to customize their communities in order to bring value to the people involved in those specific activities.</p>
                    <h5>Our Privacy Principles</h5>
                    <p>Stillpoint takes the private nature of your information very seriously.  Simply stated, we believe that what is private should remain private and that what is shared should be your decision.</p>
                    <p>Our fundamental principle is that the ownership of the content you provide should remain with you, and the visibility of what you share should be your decision.  Still, the diverse nature of our Services, and the attributes of online communications in general, are such that absolute guarantees are not possible.  Therefore, reasonable accommodations in our users’ expectations must be made in order for us to deliver – and for our users to receive -- the full measure of value and benefits available through our platform.</p>
                    <p>In order to ensure that we have a common understanding of just what rules will apply to the content and data posted or accessed through this site or any of our Services, please note the following principles:</p>
                    <ul>
                        <li>All data that you put in Wagglebee is carefully secured on company servers utilizing the best industry practices for maintaining your data security.  But because we don’t have super powers, we cannot guarantee the absolute safety of the data from super villains J – so think about what you post.</li>
                        <li>You are also responsible for the information you share within any public (non-private) activity and for carefully examining the individual security policies of any organization that you interact with on Wagglebee.  For our part, we will make our very best efforts to keep your data and your privacy secure.</li>
                        <li>This policy describes how we treat the information we collect when you visit and use Wagglebee and/or our other domains, products, advertising products, services, and/or content, including our iOS, Android, and desktop applications (collectively our <b>"Services"</b>).  When you use our Services, you consent to the collection, transfer, manipulation, storage, disclosure, sharing and other uses of your information according to the terms described in this Privacy Policy.</li>
                    </ul>
                    <p>Please read this policy carefully and consult it regularly to ensure you are up to date.</p>
                    <h5>Coverage Of This Privacy Policy</h5>
                    <p>This Privacy Policy covers our handling and treatment of information gathered when you are using or accessing our Services.  This Privacy Policy also covers our handling and treatment of any information about you that our partners share with us or that we share with our partners.</p>
                    <p>This Privacy Policy does not apply to the practices of third parties that we do not own, control, or manage, including but not limited to any third party websites, services, applications or businesses (<b>“Third Party Services”</b>).  While we try to work only with those Third Party Services that share our respect for your privacy, we simply cannot and do not take responsibility for the content or privacy policies of Third Party Services.  We encourage you to carefully review the privacy policies of all Third Party Services you access.</p>
                    <p>This Privacy Policy also does not govern what our users do within their activities (also called <b>“Swarms”</b>) and we aren't responsible for the information collection and use practices of our individual Swarm owners.  One of the great features of Wagglebee is customizability, and activity owners have a lot of flexibility in how their Swarms behave.  When you visit a Swarm in our network, that Swarm may collect more information than we do, and may provide information to third parties that we have no relationship with, including to advertisers.</p>
                    <h5>What We Collect and How We Use It</h5>
                    <p><b>Account Information:</b>  When you create an account on our Services (an <b>"Account"</b>), we’ll ask you for information such as your username, password, date of birth, and email address (<b>“Account Information”</b>).  We may use Account Information, alone or together with other information, to enhance and improve our Services, such as personalization.  We use your age to verify that you can lawfully use our Services (see below).  We use your email address to verify your Account and to communicate with you, as described in more detail below.  We also allow users to look for other users on Wagglebee network by name.</p>
                    <p><b>Children’s Information:</b>  Wagglebee is not directed to and is not to be used by children under 13 years of age.  If we learn that a minor child has set up an Account, we will take it down and delete the information in our possession associated with it.  If you learn that a minor child has provided us with personal information without your consent, please notify us immediately.</p>
                    <p><b>Personas:</b>  In Wagglebee you can have multiple account personas.  A persona allows you to organize your activities and connections according to the different roles you have in your life; e.g., personal, work, family, hobbyist, etc.  Every Wagglebee user has a default persona called “Me” that is always available.  In addition to the “Me” persona, users have the ability to add and remove additional personas according to their preferences.</p>
                    <p>A key attribute of personas is your ability to control their discoverability within Wagglebee by other users.  Discovery means whether or not the persona is searchable and locatable by other users.  If a persona is discoverable, then other users can find your persona through search.  If a persona is not discoverable, then other users can only find your persona if you send them an invitation.  Regardless of your persona’s discoverability, other users will not have access to your persona details unless you specifically grant them access.</p>
                    <p>Personas are discoverable by default, and your “Me” persona is always discoverable.  You can change the discoverability of any new persona that you create from your Persona settings.</p>
                    <p className="text-uppercase">Persona discoverability simply relates to how and if people can find you within wagglebee through search – it does not relate to the publicness or privateness of the activities you engage in.  Regardless of a persona’s discoverability, the individual activities that you create or participate in from that persona can be either public or private.</p>
                    <p>If you participate in publicly visible activities, your participation will always be public regardless of your persona’s discoverability.  Think of this as a private person going in to public – if you have a non-discoverable persona and then you participate in a public activity, your participation in that public activity will also be public.</p>
                    <p><b>Activities:</b> The individual activities that you create from any persona (regardless of that persona’s discoverability) can be either public or not, based on the settings you choose.  Public Swarms are discoverable by other Wagglebee users.  Non-public Swarms are only visible to the specific people that you invite.  As a Wagglebee user, you have complete control over who gets to see -- and participate in -- the Swarms you create, and whether or not your Swarm is discoverable by other users or requires a personal invitation for participation.</p>
                    <p><b>Email Communications with Us:</b>  As part of the Services, you may occasionally receive email and other communications from us.  Administrative communications relating to your Account (e.g., for purposes of Account recovery or password reset) are considered part of the Services and part of your Account, so you may not be able to opt-out of receiving these messages. We also may send you other kinds of emails, which you can opt-out of from your Account Settings. <b>Note that we will <u>never</u> email you to ask for your password or other Account Information; if you receive such an email, please <u className="font-color-blue">forward it to us</u>.</b></p>
                    <p><b>We send two kinds of email:</b>  those about fun and relevant things happening on Wagglebee, and those with important information about your Account.  You can opt out of the former, but not the latter.</p>
                    <p><b>Information about Your Accounts on Third Party Services:</b>  You can link your Account to certain Third Party Services.  In order to do so, you may need to provide us with your username or other user ID for a Third Party Service, and you may then be required to log into that Service. After you complete this login process, we will receive a token that allows us to access your account on that service.  That will enable us to post your content to that service if and when you ask us to.  We do not receive or store your passwords for your Third Party Service accounts.</p>
                    <p><b>Information Obtained from Third Party Services:</b>  In some cases, we may partner with Third Party Services that may provide us with certain information about you.  Such information could include, for example, your gender, if you have disclosed that information to such third party and made it available for Wagglebee to access.  To the extent we obtain such information, we may use it to develop new Services or to improve or enhance the Services we provide.</p>
                    <p>When you connect other kinds of services to your Wagglebee account, those services might also share information about you with us.  What gets shown to us should be disclosed in their privacy policies, so you should review those policies and make sure you agree with them before connecting Third Party Services to your Wagglebee account.  We may not be able to reject or block the information provided to us by the third party so you are responsible for that result.</p>
                    <p><b>User Content:</b>  As a Wagglebee user you have a lot of control over who can see and participate in your Swarms.  Swarms and other content that you share can be very public or highly confidential based on the settings you choose.  Swarms and the other content you share through our Services may be visible to everyone on Wagglebee, or to a particular organization that your persona is linked to, or just to a particular set of Wagglebee users, depending on the visibility options you select.  Also, please keep in mind that anything you share privately with another user, particularly through a Swarm or submitted post, may be posted publicly by that user.  Content published and shared publicly is accessible to everyone, including search engines, and you may lose any privacy rights you might have regarding that content.  In addition, information shared publicly may be copied and shared throughout the Internet, including through actions or features native to or compatible with the Services.</p>
                    <p>Wagglebee is designed to uniquely cater to the public and private activities of individuals and communities alike.  Make sure to choose the right visibility and options for your Swarms, and we will make sure it is visible only to the desired audience.  Our objective is to allow you to be as private or as public as you desire.</p>
                    <p><b>IP INFRINGEMENT:</b> You are responsible for the content that you share and post on Wagglebee and therefore you need to ensure that any third party rights, including intellectual property, privacy and publicity rights, are not infringed in any manner whatsoever.  In the event that any such third party rights are infringed, violated or compromised, you alone will be responsible and liable for such infringements -- we will have no responsibility or liability for those actions, whether accidental or intentional by you.</p>
                    <p className="text-uppercase"><b>By using wagglebee, you assume all risks and waive and release us from any and all claims and liability resulting from any action or omission by you that violates any third party rights.</b></p>
                    <p>So if you have any doubt about your rights to post something, don’t do it.  For our part, if you notify us (or someone else notifies us) and provides reasonable evidence of an IP infringement, we will, on a best endeavor basis, try earnestly to block such posts or data alleged to be infringing until a full and proper investigation can be conducted.  This is in addition to, and without prejudice to, our duties and responsibilities under the Digital Millennium Copyright Act, referenced in our Terms of Use posted on our site. </p>
                    <p><b>IMPROPER/ABUSIVE CONTENT:</b>  You should understand that Wagglebee functions as both a public and a private communications platform.  We believe in the facilitation of free thinking and respectful, energetic and constructive community discourse.  When participating in public activities, don’t intentionally post or share any data which may be construed as offensive morally, socially or otherwise, which is obscene in any manner, or is considered an act of hate towards any section of society -- basically don’t be a jerk!</p>
                    <p>Should any user participate in such behavior, the sole responsibility and liability with respect to such behavior and content will lie with the offending user.  We encourage other users to report such instances so that we can take proactive remedial measures, including but not limited to, blocking the offensive content at our sole discretion in whatever manner we deem most appropriate.</p>
                    <p>Wagglebee was designed to facilitate individual and community driven activities and interests, so we embrace activities that voice all manner of discourse and views so long as they are respectful.  Wherever possible we will try to provide tools and empower Swarm owners so that individual community Swarms can self-govern participation according to their own moral sensibilities.</p>
                    <p><b>Native Actions:</b>  Our Services allow you to perform native actions that are integral to our products, such as participating in a Swarm, liking or sharing a Swarm or other post, replying to content and following a Swarm.  Liking, sharing and replying are public actions – anyone who is participating in a given Swarm can expand the respective view on a post, for example, to see who liked, shared, or replied to a Swarm or post.</p>
                    <p>We use information about native actions to improve our Services, develop new Services and, particularly, to personalize your Wagglebee experience.  Personalization using this information may include presenting you with new content relevant to what you’ve participated in or liked, providing you with better search results, and showing you Swarms and other forms of data in Wagglebee that are relevant to your interests.</p>
                    <p>Participation, Shares, Likes, and Replies are a matter of public record, so if you do not intend to disclose your desires, it’s best to keep them to yourself.  But why not share who you are and what you are good at.  Let people discover your awesome Swarms and let Wagglebee present relevant Swarms to you that will thrill you, excite you and make your day.</p>
                    <p><b>Information About User Content:</b>  In some cases, we may collect information about content you provide to our Services.  For example, when it's included as part of your images, we may collect information describing your camera, camera settings, or <u className="font-color-blue">EXIF information</u>.  This information allows us to improve our Services and provide additional features and functionalities.</p>
                    <p><b>Information Related to Use of the Services:</b>  We collect information about how people use our Services.  We collect information each time you interact with (i.e., make a request to) our Services.  We use internal tools and third party applications and services to collect and analyze this information.</p>
                    <p>Some of this information may also be associated with the Internet Protocol Address (<b>“IP Address”</b>) used to access the Services; some of this information may be connected with your Account; and some may only be collected and used in aggregate form (as a statistical measure that wouldn’t identify you or your Account).  We collect your IP Address as part of all your interaction with our Services.  We may use this information about how you and others interact with us for a number of things generally related to enhancing, improving, protecting, and developing new Services.  Such actions may include providing users with personalized content; providing users with targeted offers; improving our search results; identifying trending or popular activities and content; fighting spam, malware and identity theft and generally keeping our users and community safe.  For more details, see the section below entitled <b>“Information Disclosed for Our Protection and the Protection of Others.”</b></p>
                    <p>We use information about how you interact with Wagglebee in order to personalize it for you, to keep both you and our community safe from harm, and to improve Wagglebee for everyone who uses it.</p>
                    <p><b>Information Related to Your Web Browser:</b>  We automatically receive and record information from your web browser when you interact with our Services, such as your browser type and version, what sort of device you are using, your operating system and version, your language preference, the website or service that referred you to the Services, the date and time of each request you make to the Services, your screen display information, and information from any cookies we have placed on your web browser (as described below).  We also sometimes detect whether you are using certain web browser extensions and store that information in a manner associated with your Account.  We use web browser-related information to enhance and improve the Services.</p>
                    <p><b>Location Information:</b>  In some cases we collect and store information about where you are located, such as by converting your IP Address into a rough geolocation.  We may also ask you to provide information about your location, so that we may use your geolocation information from your mobile device to geotag a post.  Location information often enables us to improve and personalize our Services by showing you relevant local content.</p>
                    <p>Wagglebee may determine your location by using spy satellite technology and ESP.  Oops, no, don’t worry, we just check out your IP address or any location data from your device/web browser that you have allowed us to see, pretty normal stuff.</p>
                    <p><b>Information Related to Your Mobile Device:</b>  We may collect and store information related to your mobile device.  In some cases, we (or who we use for mobile analytics and other services), may receive, generate, or assign your mobile device a unique identifier for the purposes described above in <b>“Information Related to Use of the Services.”</b>  We may also ask you to provide your phone number to enable new products and improvements to the Services, such as by enabling multi-factor authentication for Account login.  We will always ask you whether it’s OK for us to collect and store your phone number.</p>
                    <p><b>Information Collected Using Cookies and Web Tags:</b>  Cookies are text files that may be sent to and saved by your web browser when you access a website; your web browser stores these cookies in a way associated with each website you visit, and you can generally see your cookies through your browser settings, depending on what browser you’re using.  A web tag is code or a pixel embedded in a web page, or email, that allows Wagglebee or a third party to see that you have looked at that page.</p>
                    <p>We use cookies and web tags to enable our servers to recognize your web browser and tell us how and when you use the Services, as described above in <b>“Information Related to Use of the Services.”</b> Our cookies do not, by themselves, contain information that personally identifies you, and we don't combine the general information collected through cookies with other such information to tell us who you are.  However, we do use cookies to identify that you have logged in, and that your web browser has accessed the Services, and we may associate that information with your Account.  We may also store unique or near-unique identifiers that we associate with your Account in our cookies.  This information, in turn, is sometimes used as described above in <b>“Information Related to Use of the Services.”</b></p>
                    <p>Most web browsers have an option for turning off the cookie feature, which will prevent your browser from accepting new cookies, as well as (depending on the sophistication of your web browser) allowing you to decide on acceptance of each new cookie in a variety of ways.  If you disable cookies, you won't be able to log into your Account, and so won’t be able to use the vast majority of our Services.  Therefore, we don't recommend disabling your cookies when using the Services.  Note that, unless otherwise disclosed, this Privacy Policy covers our use of cookies only and does not cover the use of cookies by third parties.</p>
                    <p>Being able to log into your account is one of Wagglebee’s many great features.  Cookies make this possible.  Cookies and web tags also let us learn how people use Wagglebee, which in turn helps us make a better product for our users.</p>
                    <p><b>Derived Information:</b>  As described above in <b>“Native Actions”</b> and <b>“Information Related to Use of the Services,”</b> we analyze your actions on the Services in order to derive or infer characteristics that may be descriptive of your Account (for example, what kinds of Swarms you follow or what kinds of content you view, like, or share).  We use this information for all of the purposes set forth in <b>“Information Related to Use of the Services”</b> above.</p>
                    <h5>With Whom Your Information Is Shared</h5>
                    <p>We never share information we receive from you unless:  (a) we have your permission to share that information; (b) we have given you prior notice that the information will be shared, and with whom (such as in this Privacy Policy); (c) that information is aggregate information or other information that does not identify you; or (d) we are required to produce such information under subpoena, court order or other legal process.</p>
                    <p><b>Information Shared Between the Services:</b>  We may, if possible, aggregate information about your use of multiple Services and use that consolidated information to enhance and improve our Services and/or develop new Services.</p>
                    <p><b>Information You Share with Third Party Services:</b> You may access Third Party Services through our Services, for example by clicking on externally-pointing links.  You may also choose to share information that you provide to us, like your posts, with those Third Party Services (for example, by sharing posts to Twitter or Facebook).  This Privacy Policy only governs information we collect and you are responsible for reading and understanding the privacy policies applicable to Third Party Services.</p>
                    <p>You can automatically publish the posts you make on Wagglebee Swarms to other sites (like Facebook or Twitter).  That’s where our Privacy Policy ends and their policies begin.  Make sure you’re cool with the policies of any services you’re considering sharing to before connecting them to your Wagglebee account.</p>
                    <p><b>Information Shared with Our Agents in Order to Operate and Improve the Services:</b>  In some cases, we share information that we store (such as IP Addresses) with third parties, such as service providers, consultants and others (collectively, <b>"Agents"</b>), for purposes of operating, enhancing and improving the Services or for developing new products and services.  For example, we may share information with service providers in order to fight spam, and third-party consultants may have access to information in the process of improving our processes and technology.  Agents with whom we share such information for these reasons are generally bound by confidentiality obligations and do not have any right to use information we share with them beyond the scope and duration of what is necessary to assist us.</p>
                    <p><b>Information Disclosed Pursuant to Business Transfers:</b>  In some cases, we may choose to buy or sell business assets.  In these transactions, user information is typically one of the transferred business assets.  Moreover, if we (as an entity), or substantially all of our assets, were acquired, or if we go out of business or enter bankruptcy, user information would be one of the assets that is transferred or acquired by a third party.  You acknowledge that such transfers may occur, and that any acquirer of us or our assets may continue to use your Personal Information as set forth in this policy.</p>
                    <p>Should we happen to get acquired or go out of business (no way), the transfer of assets from us to our buyer may very well include user information. Recipients would generally be allowed to use it only in the ways outlined here, in the Privacy Policy you are reading right now.</p>
                    <p><b>Information Disclosed for Our Protection and the Protection of Others:</b> We believe in freedom of expression, and, to the extent practicable, we try to protect our community from baseless legal demands.  That said, we also reserve the right to access, preserve and disclose any information that we reasonably believe is necessary, in our sole discretion, to (i) satisfy any law, regulation, legal process, governmental request, or governmental order, (ii) enforce this Privacy Policy and our Terms of Service, including investigation of potential violations hereof, (iii) detect, prevent, or otherwise address fraud, security, trust, safety and/or technical issues (including exchanging information with other companies and organizations for the purposes of improving security and preventing fraud, spam, and malware), (iv) respond to user support requests, or (v) protect the rights, property, health or safety of us, our users, any third parties or the public in general, including but not limited to situations involving possible violence, suicide, or self-harm.</p>
                    <p>Don’t make us tail you.  We don’t want to. But we do need to protect everyone who uses Wagglebee from harming us, each other or themselves.  We work hard to balance privacy and community, as challenging as that sometimes is, and we’re sure you can appreciate the seriousness with which we approach these issues.</p>
                    <p><b>Information We Share with Your Consent or at Your Request:</b> If you ask us to release information that we have about your Account, we will do so if reasonable and not unduly burdensome.</p>
                    <p><b>Information Shared with Other Third Parties:</b> We may share or disclose non-private information, aggregate information or other non-personally identifying information with people and entities that we do business with.</p>
                    <h5>The Security of Your Information</h5>
                    <p>Your Account Information is protected by a password for your privacy and security.  We may enable additional security features in the future, like multi-factor authentication.  You need to prevent unauthorized access to your Account and information by creating a unique, secure and protected password and by limiting access to your computer, browser and mobile devices by logging off after accessing your Account.</p>
                    <p>We seek to protect your information (including your Account Information) by keeping it private; however, we can't guarantee the security of any information.  Unauthorized entry or use, hardware or software failure, and other factors may compromise the security of user information at any time.</p>
                    <p>It’s really important that you have a unique password for all of your accounts on the internet, including your Wagglebee account.  If you have trouble remembering passwords, you can always tell us that you forgot your password from the Wagglebee app, and you will be guided appropriately by the Services to reset it.</p>
                    <h5>What Information You Can Access</h5>
                    <p>As a registered user of our Services, you can access most information associated with your Account by logging into the Services and checking your Account Settings page.</p>
                    <h5>How to Delete Your Account and What Happens When You Delete Your Account</h5>
                    <p>If you want to delete your Account, you can do so from your Account Settings page or by emailing us and providing proof of authority over the Account.  What constitutes "proof of authority" will vary depending on the circumstances, but generally, it will require sufficient identifying information so that we can be confident you are the Account owner.</p>
                    <p>Deleting your Account may not fully remove the content you have published from our systems, as caches, backups, copies and/or references to your Account Information may not be immediately removed.  In addition, given the nature of sharing on the Services, some of the public activity on your Account prior to deletion (such as content that you have shared with others) may remain stored on our servers and accessible to the public.</p>
                    <p>We are sure that you will do great things on Wagglebee and it will become an important source of enjoyment, but if it ever comes time to say goodbye, then you’re free to delete your account at any time and for any reason.  The deletion will be effective immediately, for the most part, but it might take a little while to scrub everything from our archives and backups.</p>
                    <p>What won’t get scrubbed are shares of your posts.  Those will remain on whatever Swarm they are shared with.  Therefore, as referenced earlier, it can be really, really hard to delete something once it goes out onto the internet, so be sure you’re only publishing stuff to everyone on Wagglebee that you’re certain you want to publish.</p>
                    <h5>Changes to This Privacy Policy</h5>
                    <p>We may amend this Privacy Policy from time to time, using the process for modifications set forth in our Terms of Service at our sole discretion.  Use of information we collect is subject to the Privacy Policy in effect at the time such information is collected.</p>

                </Container>

                <FooterView></FooterView>
            </>
        );
    }
}

export default PrivacyPolicyView;