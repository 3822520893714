import React, { Component } from "react";

class FeedbackMessageView extends Component {

    render() {
        return (
            <div>
                <h1>Thank you for contacting us!</h1>
                <h4>We're looking forward to helping you as quickly as we can.</h4>
            </div>
        );
    }
}

export default FeedbackMessageView;