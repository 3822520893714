export const ErrorCodes = {
    REGISTRATION_STATUS_VERIFICATIONIDMISSING: 5140,
    REGISTRATION_STATUS_HIVESHORTNAMEMISSING: 5141,
    HIVEREGISTRATION_STATUS_SUCCESSFULL: 5143, //SUCCESS
    REGISTRATION_STATUS_EXISTINGUSERINACTIVESTATE: 5146,
    REGISTRATION_STATUS_VERIFICATIONCODEMISSING: 5147,
    REGISTRATION_STATUS_EMAILREGISTRATIONDOESNOTEXISTS: 5148,
    REGISTRATION_STATUS_HIVEDOESNOTEXISTS: 5149,
    REGISTRATION_STATUS_HIVEISINACTIVE: 5150,
    REGISTRATION_STATUS_PENDINGHIVEATTACHREQUEST: 5151,
    REGISTRATION_STATUS_EMAILVERIFICATIONSUCCESS: 5152, //SUCCESS
    REGISTRATION_STATUS_EMAILVERIFICATIONFAILED: 5153,
    REGISTRATION_STATUS_REGISTRATIONEMAILMISSING: 5154,
    REGISTRATION_STATUS_EMAILREGISTEREDWITHWAGGLEBEE: 5155, //SUCCESS
    REGISTRATION_STATUS_REGISTRATIONFAILED: 5156,
    REGISTRATION_STATUS_VERIFICATIONCODEDOESNOTMATCHEMAIL: 5157,
    REGISTRATION_STATUS_ALREADYMEMEBEROFHIVE: 5158,
    CONTACTUS_STATUS_SENDEREMAILMISSING: 5163,
    CONTACTUS_STATUS_EMAILSUBJECTMISSING: 5164,
    CONTACTUS_STATUS_EMAILMESSAGEMISSING: 5165,
    CONTACTUS_STATUS_EMAILSENT: 5166,
    CONTACTUS_STATUS_FAILEDTOSENDEMAIL: 5167
}