import React, { Component } from 'react';

import HeaderStaticView from './HeaderStaticView';
import FooterView from './FooterView';

import { Container, Row, Button } from 'reactstrap';

import { SPSITEMessages } from '../utils/Messages';

import pageNotFoundImage from '../images/page-not-found.gif';

class PageNotFoundView extends Component {

    render() {
        return (
            <>
                <HeaderStaticView></HeaderStaticView>

                <section className="first-section bottom-120">
                    <Container>
                        <Row className="section_1_row">
                            <div className="col-md-6">
                                <div className="section_1_left">
                                    <h1>{SPSITEMessages.PAGE_NOT_FOUND_TITLE}</h1>
                                    <p>
                                        {SPSITEMessages.PAGE_NOT_FOUND_DESCRIPTION1}<><br /></>{SPSITEMessages.PAGE_NOT_FOUND_DESCRIPTION2}
                                    </p>
                                    <Button href="/" color="primary">Home</Button>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <img className="section-1-image disable-selection disable-drag" src={pageNotFoundImage} alt="error" ></img>
                            </div>
                        </Row>
                    </Container>
                </section>

                <FooterView></FooterView>
            </>
        );
    }
}

export default PageNotFoundView;