import React, { Component } from 'react';
import brandLogo from '../images/brand-logo.png';

class HeaderStaticView extends Component {

    render() {

        return (
            <header className="header-area">
                <div className="container h-100">
                    <div className="row h-100">
                        <div className="col-sm header-container">
                            <div style={{ flex: 1 }}>
                                <a className="logo_image_container" href="/">
                                    <img src={brandLogo} className="branding-logo disable-selection disable-drag" alt="logo"></img>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        );
    }
}

export default HeaderStaticView;