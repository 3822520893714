import React, { Component } from 'react';

import brandLogo from '../images/brand-logo.png';

class HeaderRegistrationView extends Component {

    render() {

        return (
            <header className="header-area">
                <div className="container h-100">
                    <div className="row h-100 justify-content-center">

                        <div className="col-sm">
                            <a className="logo_image_container justify-center" href="/">
                                <img src={brandLogo} className="branding-logo disable-selection disable-drag" alt="logo"/>
                            </a>
                        </div>

                    </div>
                </div>
            </header>
        );
    }
}

export default HeaderRegistrationView;