import React, { Component } from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';

import HomeView from './containers/HomeView';
import PageNotFoundView from './containers/PageNotFoundView';
import PrivacyPolicyView from './containers/About/PrivacyPolicyView';
import TermsOfUseView from './containers/About/TermsOfUseView';
import ContactUsView from './containers/About/ContactUsView';

class App extends Component {

    render() {
        return (
            <div className="appContainer">
                <BrowserRouter>
                    <Switch>
                        <Route path='/' component={HomeView} exact />
                        <Redirect from='/wbpp.htm' to='/privacy-policy' />
                        <Route path='/privacy-policy' component={PrivacyPolicyView} />
                        <Route path='/terms-of-use' component={TermsOfUseView} />
                        <Route path='/contact-us' component={ContactUsView} />
                        <Route component={PageNotFoundView} />
                    </Switch>
                </BrowserRouter>
            </div>
        );
    }
}

export default App;
